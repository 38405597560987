import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "../style/normalize.css";
import "../style/all.scss";

//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
const BlogIndex = () => {
  return (
    <Layout>
      <SEO
        title="Herzlich Willkommen"
        keywords={[
          `michael gwerder`,
        ]}
      />
        <header className="page-head">
          <h2 className="page-head-title">
            Grüezi und herzlich Willkommen!
          </h2>
          <h3>Zurzeit ist hier nicht viel los.</h3>
        </header>
        
    </Layout>
  );
};

export default BlogIndex;
